.header {
  margin-bottom: 24px !important;
  background-color: whitesmoke !important;
  padding: 0 22px 0 0 !important;
  padding-left: 16px;
  border-radius: 6px;
  box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0) !important;
}

.header .header-link {
  font-weight: 500;
  color: darkslategray !important;
}

.header .header-link:hover {
  color: var(--main-color) !important;
}

.header .header-link-active {
  font-weight: 500;
  color: var(--main-color) !important;
}

.header .header_home {
  background-color: var(--main-color) !important;
  padding: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.header .header-right {
  display: flex !important;
  align-items: center;
}

.header .header-left {
  margin-right: auto;
}
